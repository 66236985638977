<template>
  <div class="esg-daas-container">
    <div class="button-group">
      <router-link
        :to="{ name: 'documents' }"
        class="button"
        :class="{
          active: $store.state.pmtDocuments.activeButton === 'button1'
        }"
        @click.native="toggle('button1')"
      >
        Documents
      </router-link>
      <router-link
        :to="{ name: 'PMTdashboard' }"
        class="button"
        :class="{
          active: $store.state.pmtDocuments.activeButton === 'button2'
        }"
        @click.native="toggle('button2')"
      >
        Dashboard
      </router-link>
    </div>
    <div v-if="$store.state.pmtDocuments.activeButton === 'button1'">
      <PMTDocuments :key="gridKey" :theme="user_preference_mode"></PMTDocuments>
    </div>
    <div v-else-if="$store.state.pmtDocuments.activeButton === 'button2'">
      <PMTDashboard></PMTDashboard>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { metaTitle } from '../../constant/data'
import PMTDocuments from './PMTDocuments.vue'
import PMTDashboard from './PMTDashboard.vue'
import CompanySearch from '../../components/EsgDaas/CompanySearch.vue'
import moment from 'moment'

export default {
  name: 'Pmt',
  metaInfo() {
    return {
      title: metaTitle.pmtDocuments
    }
  },
  components: {
    PMTDocuments,
    CompanySearch,
    PMTDashboard
  },
  data() {
    return {
      gridKey: 0,
      activeButton: 'button1'
    }
  },
  computed: {
    ...mapState('pmtDocuments', [
      'pmtDocumentsFilter',
      'searchCompanyDocuments'
    ]),
    ...mapState('common', ['user_preference_mode']),
    pmtDocumentsTotalCount() {
      return this.pmtDocumentsFilter.totalCount
    }
  },
  watch: {
    user_preference_mode() {
      this.gridKey = this.gridKey + 1
    }
  },
  created() {
    const routeName = this.$route.name
    if (routeName === 'documents') {
      this.$store.commit('pmtDocuments/setActiveButton', 'button1')
    } else if (routeName === 'PMTdashboard') {
      this.$store.commit('pmtDocuments/setActiveButton', 'button2')
    }

    const pmtDocumentsFilter = JSON.parse(
      localStorage.getItem('PMT_DOCUMENTS_FILTERS')
    )

    if (pmtDocumentsFilter && pmtDocumentsFilter.filterOptions) {
      pmtDocumentsFilter.filterOptions = pmtDocumentsFilter.filterOptions.map(
        (filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'updatedAt' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate'
            ) {
              return {
                ...filter,
                value: new Date(filter.value)
              }
            }
            return {
              ...filter
            }
          })
          return { filters: filterList, logic: logic }
        }
      )
      this.updatePmtDocumentsFilter(pmtDocumentsFilter)
    } else {
      this.updatePmtDocumentsFilter({
        limit: 10,
        offset: 0
      })
    }
  },
  async mounted() {
    const companyFilter = JSON.parse(localStorage.getItem('COMPANY_FILTER'))
    this.updateCompanyFilter(companyFilter)
    if (this.pmtDocumentsFilter.searchContent) {
      this.pmtDocumentsFilter.searchContent =
        this.pmtDocumentsFilter.searchContent.trim()
    }

    if (companyFilter && companyFilter.length > 0) {
      const companyFilterArr = []
      const finalComapnyArr = []
      const companyObj = {}
      companyObj.logic = 'or'
      companyFilter.forEach((ele) => {
        const obj = {}
        obj.operator = 'contains'
        obj.field = 'companyName'
        obj.value = ele.name
        companyFilterArr.push(obj)
      })
      companyObj.filters = companyFilterArr
      finalComapnyArr.push(companyObj)
      const disclosureArr = this.pmtDocumentsFilter.filterOptions
      if (disclosureArr) {
        const companyNewArray = [...finalComapnyArr, ...disclosureArr]
        this.updateSearchCompanyDocuments(companyNewArray)
        this.getTableData(
          this.pmtDocumentsFilter.offset,
          this.pmtDocumentsFilter.limit,
          this.pmtDocumentsFilter.searchContent,
          companyNewArray
        )
      } else {
        const companyNewArray = [...finalComapnyArr]
        this.updateSearchCompanyDocuments(companyNewArray)
        this.getTableData(
          this.pmtDocumentsFilter.offset,
          this.pmtDocumentsFilter.limit,
          this.pmtDocumentsFilter.searchContent,
          companyNewArray
        )
      }
    } else {
      this.updateSearchCompanyDocuments(this.pmtDocumentsFilter.filterOptions)
      this.getTableData(
        this.pmtDocumentsFilter.offset,
        this.pmtDocumentsFilter.limit,
        this.pmtDocumentsFilter.searchContent,
        this.pmtDocumentsFilter.filterOptions
      )
    }
  },
  methods: {
    ...mapActions('pmtDocuments', [
      'getPmtDocumentsList',
      'updatePmtDocumentsFilter',
      'updateFilteredColumnList',
      'updateCompanyFilter',
      'updateSearchCompanyDocuments',
      'handleToggle'
    ]),
    async getTableData(offset, limit, searchContent = null, filterList = null) {
      if (searchContent) {
        searchContent = searchContent.trim()
      }
      let localFilterList = null
      if (filterList) {
        localFilterList = filterList.map((filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'updatedAt' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate' ||
              filter.field === 'pub_date' ||
              filter.field === 'created_at'
            ) {
              return {
                ...filter,
                value: moment(moment(filter.value)).format('YYYY-MM-DD')
              }
            } else if (filter.field === 'calcAmtCombined') {
              return {
                ...filter,
                field: 'calcAmt',
                value: filter.value.trim()
              }
            } else if (filter.field === 'origAmtCombined') {
              return {
                ...filter,
                field: 'origAmt',
                value: filter.value.trim()
              }
            }
            return {
              ...filter,
              value:
                typeof filter.value === 'string'
                  ? filter.value.trim()
                  : filter.value
            }
          })
          return { filters: filterList, logic: logic }
        })
      }
      const reqData = {
        searchContent: searchContent || null,
        offset: offset,
        limit: limit,
        filterOptions: localFilterList
      }
      await this.getPmtDocumentsList(reqData)
    },
    toggle(button) {
      if (button === 'button1') {
        this.$router.push({ name: 'documents' })
      } else if (button === 'button2') {
        this.$router.push({ name: 'PMTdashboard' })
      }
      this.$store.commit('pmtDocuments/setActiveButton', button)
    }
  }
}
</script>
<style lang="scss" src="./pmt.scss" scoped></style>
