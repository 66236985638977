import { render, staticRenderFns } from "./PMTDocuments.vue?vue&type=template&id=246cc1eb&scoped=true&"
import script from "./PMTDocuments.vue?vue&type=script&lang=js&"
export * from "./PMTDocuments.vue?vue&type=script&lang=js&"
import style0 from "./PMTDocuments.vue?vue&type=style&index=0&id=246cc1eb&prod&lang=scss&"
import style1 from "./PMTDocuments.vue?vue&type=style&index=1&id=246cc1eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246cc1eb",
  null
  
)

export default component.exports